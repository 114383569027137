import { useNavigate } from "react-router-dom";
import {
  signInWithPopup,
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  // FacebookAuthProvider,
  signOut,
  // TwitterAuthProvider,
  // OAuthProvider,
} from "firebase/auth";
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { message, Image, Button, Space } from "antd";
import signInGoogle from "../../assets/images/google-logo.svg";
import signInGithub from "../../assets/images/github-mark.svg";
// import signInFacebook from "../../assets/images/icons8-facebook-48.png";
import { getDays } from "../../lib/utils";
// import signInTwitter from "../../assets/images/twitter.svg";
// import signInYahoo from "../../assets/images/yahoo-svgrepo-com.svg";
// import signInMicrosoft from "../../assets/images/ms-symbollockup_signin_light_short.svg";

function OAuth() {
  const navigate = useNavigate();

  const clickGoogleHandler = async () => {
    try {
      const auth = getAuth();
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const docRef = doc(db, "users", user.uid);
      const userChatsRef = doc(db, "userChats", user.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(docRef, {
          name: user.displayName,
          // email: user.email,
          uid: user.uid,
          userRole: "USER",
          dailyLikes: 0,
          disabled: false,
          lastUpdated: serverTimestamp(),
          avatarUrl: user.photoURL,
          profileCompleted: false,
          timestamp: serverTimestamp(),
        });
        await setDoc(userChatsRef, {});
        message.success("Great!. Lets create a profile!");
        navigate("/add-profile");
      } else {

        if (docSnap.data().profileCompleted) {
          if (
            docSnap.data().dailyLikes === 7 &&
            getDays(docSnap.data().lastUpdated !== 0)
          ) {
            await setDoc(docRef, {
              dailyLikes: 0,
              lastUpdated: serverTimestamp(),
            });
          }
          navigate("/");
        } else if ( docSnap.data().disabled ) {
          signOut(auth);
          message.error("Your account is disabled.")
        } 
        
        else {
          message.success("Lets complete your profile!");
          navigate("/add-profile");
        }
      }
      // if (user?.profileCompleted) {
      //   navigate("/");
      // } else {
      //   navigate("/add-profile");
      // }
    } catch (error) {
      message.error(`Couldn't authorize with Google! Try later.`, 2);
    }
  };

  const clickGithubHandler = async () => {
    try {
      const auth = getAuth();
      const provider = new GithubAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const docRef = doc(db, "users", user.uid);
      const userChatsRef = doc(db, "userChats", user.uid);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) {
        await setDoc(doc(db, "users", user.uid), {
          name: user.displayName,
          // email: user.email,
          uid: user.uid,
          userRole: "USER",
          dailyLikes: 0,
          avatarUrl: user.photoURL,
          profileCompleted: false,
          timestamp: serverTimestamp(),
        });
        await setDoc(userChatsRef, {});
      }
      if (user.profileCompleted) {
        if (
          docSnap.data().dailyLikes === 7 &&
          getDays(docSnap.data().lastUpdated !== 0)
        ) {
          await setDoc(docRef, {
            dailyLikes: 0,
            lastUpdated: serverTimestamp(),
          });
        }
        navigate("/");
      } else {
        navigate("/add-profile");
      }
    } catch (error) {
      switch (error.code) {
        case "auth/account-exists-with-different-credential":
          message.error(`Already have account with different provider.`, 2);
          break;
        default:
          message.error(`Couldn't authorize with Github! Try later.`, 2);
      }
    }
  };

  // const clickFacebookHandler = async () => {
  //   try {
  //     const auth = getAuth();
  //     const provider = new FacebookAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     const docRef = doc(db, "users", user.uid);
  //     const userChatsRef = doc(db, "userChats", user.uid);
  //     const docSnap = await getDoc(docRef);

  //     console.log(user);

  //     if (!docSnap.exists()) {
  //       await setDoc(doc(db, "users", user.uid), {
  //         name: user.displayName,
  //         // email: user.email,
  //         uid: user.uid,
  //         userRole: "USER",
  //         dailyLikes: 0,
  //         avatarUrl: user.photoURL,
  //         profileCompleted: false,
  //         timestamp: serverTimestamp(),
  //       });
  //       await setDoc(userChatsRef, {});
  //     }
  //     if (user.profileCompleted) {
  //       if (
  //         docSnap.data().dailyLikes === 7 &&
  //         getDays(docSnap.data().lastUpdated !== 0)
  //       ) {
  //         await setDoc(docRef, {
  //           dailyLikes: 0,
  //           lastUpdated: serverTimestamp(),
  //         });
  //       }
  //       navigate("/");
  //     } else {
  //       navigate("/add-profile");
  //     }
  //   } catch (error) {
  //     switch (error.code) {
  //       case "auth/account-exists-with-different-credential":
  //         message.error(`Already have account with different provider.`, 2);
  //         break;
  //       default:
  //         message.error(`Couldn't authorize with Facebook! Try later.`, 2);
  //     }
  //   }
  // };

  // const clickTwitterHandler = async () => {
  //   try {
  //     const auth = getAuth();
  //     const provider = new TwitterAuthProvider();
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     console.log(user);

  //     // const docRef = doc(db, "users", user.uid);
  //     // const userChatsRef = doc(db, "userChats", user.uid);
  //     // const docSnap = await getDoc(docRef);

  //     // if (!docSnap.exists()) {
  //     //   await setDoc(doc(db, "users", user.uid), {
  //     //     name: user.displayName,
  //     //     email: user.email,
  //     //     uid: user.uid,
  //     //     userRole: "USER",
  //     //     avatarUrl: user.photoURL,
  //     //     profileCompleted: false,
  //     //     timestamp: serverTimestamp(),
  //     //   });
  //     //   await setDoc(userChatsRef, {});
  //     // }
  //     // if (user.profileCompleted) {
  //     //   navigate("/");
  //     // } else {
  //     //   navigate("/add-profile");
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //     message.error(`Couldn't authorize with Twitter! Try later.`, 2);
  //   }
  // };

  // const clickYahooHandler = async () => {
  //   try {
  //     const auth = getAuth();
  //     const provider = new OAuthProvider("yahoo.com");
  //     provider.setCustomParameters({
  //       // Prompt user to re-authenticate to Yahoo.
  //       prompt: 'login',
  //     });
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     console.log(user, result.additionalUserInfo.profile);

  //     // const docRef = doc(db, "users", user.uid);
  //     // const userChatsRef = doc(db, "userChats", user.uid);
  //     // const docSnap = await getDoc(docRef);

  //     // if (!docSnap.exists()) {
  //     //   await setDoc(doc(db, "users", user.uid), {
  //     //     name: user.displayName,
  //     //     email: user.email,
  //     //     uid: user.uid,
  //     //     userRole: "USER",
  //     //     avatarUrl: user.photoURL,
  //     //     profileCompleted: false,
  //     //     timestamp: serverTimestamp(),
  //     //   });
  //     //   await setDoc(userChatsRef, {});
  //     // }
  //     // if (user.profileCompleted) {
  //     //   navigate("/");
  //     // } else {
  //     //   navigate("/add-profile");
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //     message.error(`Couldn't authorize with Yahoo! Try later.`, 2);
  //   }
  // };

  //   const clickMicrosoftHandler = async () => {
  //   try {
  //     const auth = getAuth();
  //     const provider = new OAuthProvider("microsoft.com");
  //     // provider.setCustomParameters({
  //     //   prompt: "consent",
  //     //   tenant: "f8cdef31-a31e-4b4a-93e4-5f571e91255a",
  //     // })
  //     const result = await signInWithPopup(auth, provider);
  //     const user = result.user;

  //     console.log(user, result.additionalUserInfo.profile);

  //     // const docRef = doc(db, "users", user.uid);
  //     // const userChatsRef = doc(db, "userChats", user.uid);
  //     // const docSnap = await getDoc(docRef);

  //     // if (!docSnap.exists()) {
  //     //   await setDoc(doc(db, "users", user.uid), {
  //     //     name: user.displayName,
  //     //     email: user.email,
  //     //     uid: user.uid,
  //     //     userRole: "USER",
  //     //     avatarUrl: user.photoURL,
  //     //     profileCompleted: false,
  //     //     timestamp: serverTimestamp(),
  //     //   });
  //     //   await setDoc(userChatsRef, {});
  //     // }
  //     // if (user.profileCompleted) {
  //     //   navigate("/");
  //     // } else {
  //     //   navigate("/add-profile");
  //     // }
  //   } catch (error) {
  //     console.log(error);
  //     message.error(`Couldn't authorize with Microsoft! Try later.`, 2);
  //   }
  // };

  return (
    <Space direction="horizontal">
      <Button
        block
        size="large"
        type="default"
        onClick={clickGoogleHandler}
        style={{ marginBottom: "12px" }}
      >
        <>
          <Space>
            <Image
              preview={false}
              src={signInGoogle}
              alt="SignIn with Google"
              width="24px"
            />{" "}
          </Space>
        </>
      </Button>
      <Button
        block
        size="large"
        type="default"
        onClick={clickGithubHandler}
        style={{ marginBottom: "12px" }}
      >
        <>
          <Space>
            <Image
              preview={false}
              src={signInGithub}
              alt="SignIn with Github"
              width="24px"
            />{" "}
            {"  "}
          </Space>
        </>
      </Button>
      {/* <Button
        block
        size="large"
        type="default"
        onClick={clickFacebookHandler}
        style={{ marginBottom: "12px" }}
      >
        <>
          <Space>
            <Image
              preview={false}
              src={signInFacebook}
              alt="SignIn with Github"
              width="24px"
            />
            {""}
            Continue with Facebook
          </Space>
        </>
      </Button> */}
      {/* <Button
        type="link"
        onClick={clickTwitterHandler}
        style={{ marginBottom: "12px" }}
      >
        <Button>
          <Space>
            <Image
              preview={false}
              src={signInTwitter}
              alt="SignIn with Twitter"
              width="24px"
            />{" "}
            Continue with Twitter
          </Space>
        </Button>
      </Button> */}
      {/* <Button
        block
        size="large"
        type="default"
        onClick={clickYahooHandler}
        style={{ marginBottom: "12px" }}
      >
        <>
          <Space>
            <Image
              preview={false}
              src={signInYahoo}
              alt="SignIn with Yahoo"
              width="24px"
            />{" "}
            Continue with Yahoo
          </Space>
        </>
      </Button> */}
      {/* <Button
        type="link"
        onClick={clickMicrosoftHandler}
        style={{ marginBottom: "12px" }}
      >
        <>
          <Space>
            <Image
              preview={false}
              src={signInMicrosoft}
              alt="SignIn with Yahoo"
              width="56px"
            />{" "}
            Continue with Microsoft
          </Space>
        </>
      </Button> */}
    </Space>
  );
}

export default OAuth;
