import './App.css';
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import AppHeader from './components/common/header';
import FooterCopyright from './components/common/footerCopyright';
import AppHome from './pages/home';
import AppAbout from './pages/about';
import AppFAQ from './pages/faq';
// import AppContact from './pages/contact';
import AddProfile from './pages/profiles/AddProfile';
import PrivateRoute from './components/common/PrivateRoute';
import { Layout } from 'antd';
import Profile from './pages/profiles/Profile';
import Search from './pages/profiles/Search';
import NoData from './pages/noData';
import Register from './components/auth/AdminRegister';
import Login from './components/auth/AdminLogin';
import ForgotPassword from './components/auth/AdminForgotPassword';
import Interests from './pages/profiles/Interests';
import { UserProvider } from './contexts/UserContext';
import Blog from './pages/blogs/blog';
import Blogs from './pages/blogs/blogs';
import AddBlog from './pages/blogs/addBlog';
import Messenger from './pages/messenger/Messenger';
import EditProfile from './pages/profiles/EditProfile';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import AppDelete from './pages/delete';

const { Header, Content, Footer } = Layout;

function App() {
  return (
    <UserProvider>
      <Router>
        <div id="page-container">
          <Layout className="layout">
            <div id="content-wrap">
              <Header>
                <AppHeader />
              </Header>
              <Content>
                <Routes>
                <Route path='/register' element={<Register />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/forgot-password' element={<ForgotPassword />} />
                  <Route path='/' element={<AppHome />} />
                  <Route path='/about' element={<AppAbout />} />
                  <Route path='/faq' element={<AppFAQ />} />
                  {/* <Route path='/contact' element={<AppContact />} /> */}
                  <Route path='/blogs/:keyword?' element={<Blogs />} />
                  <Route path="/blog/:id" element={<Blog />} />
                  <Route path='/delete' element={<PrivateRoute route='/login' />}>
                    <Route path='/delete' element={<AppDelete />} />
                  </Route>
                  <Route path='/messenger' element={<PrivateRoute route='/login' />}>
                    <Route path='/messenger' element={<Messenger />} />
                  </Route>
                  <Route path='/add-blog' element={<PrivateRoute route='/login' />}>
                    <Route path='/add-blog' element={<AddBlog />} />
                  </Route>
                  <Route path='/add-profile' element={<PrivateRoute route='/login' />}>
                    <Route path='/add-profile' element={<AddProfile />} />
                  </Route>
                  <Route path='/edit-profile' element={<PrivateRoute route='/login' />}>
                    <Route path='/edit-profile' element={<EditProfile />} />
                  </Route>
                  <Route path='/profile-page/:id' element={<PrivateRoute route='/login' />}>
                    <Route path='/profile-page/:id' element={<Profile />} />
                  </Route>
                  <Route path='/interests' element={<PrivateRoute route='/login' />}>
                    <Route path='/interests' element={<Interests />} />
                  </Route>
                  <Route path='/search' element={<PrivateRoute route='/login' />}>
                    <Route path='/search' element={<Search />} />
                  </Route>
                  <Route path="/privacy" element={<Privacy />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="*" element={<NoData />} />
                </Routes>
              </Content>
            </div>
            <Footer id="footer" className="footer">
              <FooterCopyright />
            </Footer>
          </Layout>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;