import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { Button } from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { useUserData } from "../../hooks/useUsers";
import { onAddFriend } from "../../hooks/onAddFriend";
import { getDays } from "../../lib/utils";
// import { onRemoveFriend } from "../../hooks/onRemoveFriend";

export default function LikeProfile({ id, userId }) {
  const dailyLimit  = parseInt(process.env.REACT_APP_DAILY_LIKES_LIMIT);
  const [user] = useAuthState(auth);
  const { userData } = useUserData(id);
  const { loading, userData: friendData } = useUserData(userId);

  const [isFriendRequested, setIsFriendRequested] = React.useState(
    userData?.friendRequests && userData.friendRequests.some(friend => friend === user?.uid)
  );

  const [dailyLikes, setDailyLikes] = React.useState(
    friendData?.dailyLikes && friendData?.dailyLikes === dailyLimit && getDays(friendData && friendData?.lastUpdated) === 0
  );

  const [isFriendReceived, setIsFriendReceived] = React.useState(
    friendData?.friendRequests && friendData.friendRequests.some(friend => friend === id)
  );

  const [isFriendAdded, setIsFriendAdded] = React.useState(
    userData?.friendRequests && userData?.friends?.includes(user?.uid)
  );

  const [isResetLikes, setIsResetLikes] = React.useState(
    friendData?.dailyLikes && friendData?.dailyLikes === dailyLimit && getDays(friendData && friendData?.lastUpdated) !== 0
  )

  async function handleFriendAdd() {
    await onAddFriend(id, user.uid, isFriendRequested, isResetLikes);
  }

  React.useEffect(() => {

    if (loading) return;

    if (friendData) {
      setIsFriendReceived(
        friendData?.friendRequests && friendData.friendRequests.some(friend => friend === id)
      );

      setDailyLikes(
        friendData?.dailyLikes && friendData?.dailyLikes === dailyLimit && getDays(friendData?.lastUpdated) === 0
      )

      setIsFriendRequested(
        userData?.friendRequests && userData?.friendRequests?.some(friend => friend === user?.uid)
      );
      setIsFriendAdded(
        userData?.friends && userData?.friends?.includes(user.uid)
      );

      setIsResetLikes(
        friendData?.dailyLikes === dailyLimit && getDays(friendData && friendData?.lastUpdated) !== 0
      )
    }


  }, [userData, loading, friendData, dailyLimit, user.uid, id]);

  return (
    <div>
      {auth.currentUser && (
        <>
          {" "}
          {(dailyLikes) ? (
            <Button type="default" color="primary" disabled>
              <HeartFilled />
              Daily Likes Used.
            </Button>
          ) : isFriendReceived ? (
            <Button type="default" color="primary" disabled>
              <HeartFilled />
              Interest Received.
            </Button>
          ) : isFriendAdded ? (
            <Button type="default" color="primary" disabled>
              <HeartFilled />
              Interest Accepted.
            </Button>
          ) : !isFriendRequested ? (
            <Button type="default" color="primary" onClick={handleFriendAdd}>
              <HeartOutlined />
              Send Interest
            </Button>
          ) : (
            <Button type="default" color="primary" disabled>
              <HeartFilled />
              Interest Sent.
            </Button>
          )}
        </>
      )}
    </div>
  );
}
