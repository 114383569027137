import React, { useState } from "react";
import { Timestamp, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, db, auth } from "../../firebaseConfig";
import { useAuthState } from "react-firebase-hooks/auth";
import { updateProfile } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { userName, getAge } from "../../lib/utils";
import { useUserData } from "../../hooks/useUsers";
import maleLogo from "../../assets/images/icons8-man-in-tuxedo-48.png";
import femaleLogo from "../../assets/images/icons8-female-48.png";
import kidsLogo from "../../assets/images/icons8-kids-40.png";
import noKidsLogo from "../../assets/images/icons8-no-kids-50.png";

import {
  Space,
  Switch,
  Spin,
  Button,
  Row,
  Col,
  Card,
  Form,
  Radio,
  Select,
  Input,
  InputNumber,
  Typography,
  DatePicker,
  TimePicker,
  Flex,
  Upload,
  Progress,
  message,
} from "antd";
import dayjs from "dayjs";
import jsonData from "../../data/data.json";
import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;
const dateFormat = "MM/DD/YYYY";

export default function AddProfile() {
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [user] = useAuthState(auth);
  const { loading: userDataLoading, userData } = useUserData(user?.uid);
  const [progress, setProgress] = useState(0);
  let navigate = useNavigate();

  React.useEffect(() => {
    if (userDataLoading) return;
    if (userData && userData.profileCompleted) {
      navigate("/edit-profile");
    } else {
      return;
    }
  }, [user, userData, userDataLoading, navigate]);

  const [contactValues, setContactValues] = useState([]);

  const [formData, setFormData] = useState({
    profileId: "",

    firstName: "",
    lastName: "",
    gender: "",
    maritalStatus: "",
    haveKids: "",
    kids: 0,
    kidsLivingStatus: "",
    wantKids: "",
    currentResidence: "",
    citizenOf: "",
    motherTongue: "",
    spokenLanguages: "",

    subCaste: "",
    placeOfBirth: "",
    dateOfBirth: "",
    timeOfBirth: "",
    raasi: "",
    manglik: "",
    manglikType: "",

    education: "",
    educationDetails: "",
    additionalEducationInfo: "",
    employment: "",
    occupation: "",
    additionalEmploymentInfo: "",
    currency: "",
    salary: 0,
    salaryType: "",

    familyValues: "",
    familyStatus: "",
    familyType: "",
    fathersName: "",
    fathersOccupation: "",
    mothersName: "",
    mothersOccupation: "",
    brothers: 0,
    brothersMarital: 0,
    sisters: 0,
    sistersMarital: 0,
    nativePlace: "",

    smoking: "",
    tobacco: "",
    drinking: "",
    drugs: "",
    diet: "",
    height: "",
    bodyType: "",
    disability: "",

    contact: [],

    privacy: true,
    userName: "",

    aboutSelf: "",
    requirements: "",
    images: {},
    createdAt: Timestamp.now().toDate(),
  });

  const handleContactsChange = (i, e, n) => {
    const newContactValues = [...contactValues];
    newContactValues[i][n] = n === "private" ? e : e.target.value;
    setContactValues(newContactValues);
  };

  const addContactFields = (event) => {
    if (event === "add") {
      setContactValues([
        ...contactValues,
        { contactName: "", contactNumber: "", private: false },
      ]);
    }
  };

  const removeContactFields = (i) => {
    const newContactValues = [...contactValues];
    newContactValues.splice(i, 1);
    setContactValues(newContactValues);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleRadio = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, dateString, id) => {
    setFormData({ ...formData, [id]: dayjs(date).toDate() });
  };

  const handleTimeChange = (time, timeString, id) => {
    setFormData({ ...formData, [id]: dayjs(time).format("h:mm:ss A") });
  };

  const handleSelect = (name) => (value) => {
    setFormData({ ...formData, [name]: value });
  };

  const handleNumberInput = (name) => (value) => {
    setFormData({ ...formData, [name]: value });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setFormData((prev) => ({
      ...prev,
      images: e?.fileList,
    }));
    return e?.fileList;
  };

  const nextStep = async () => {
    try {
      await form.validateFields();
      setCurrent((current) => {
        return current < 9 ? current + 1 : current;
      });
    } catch { }
  };

  const prevStep = () =>
    setCurrent((current) => (current > 0 ? current - 1 : current));

  const handleFinish = async (values) => {
    setLoading(true);
    try {
      const storageImage = async (img) => {
        return new Promise((resolve, reject) => {
          const storageRef = ref(storage, `/images/${Date.now()}-${uuidv4()}`);
          const uploadImage = uploadBytesResumable(
            storageRef,
            img.originFileObj
          );

          uploadImage.on(
            "state_changed",
            (snapshot) => {
              const progressPercent = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgress(progressPercent);
            },
            (error) => {
              reject(error);
            },
            () => {
              // Upload completed successfully, now we can get the download URL
              getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
                resolve({ uid: uuidv4(), url: downloadURL });
              });
            }
          );
        });
      };

      const profileImages = await Promise.all(
        [...formData.images].map((img) => storageImage(img))
      ).catch((error) => {
        alert(error);
        return;
      });

      const profileDoc = {
        // Basic Information
        profileId: uuidv4(),
        firstName: formData.firstName,
        lastName: formData.lastName,
        gender: formData.gender,
        maritalStatus: formData.maritalStatus,
        haveKids: formData.haveKids,
        kids: formData.haveKids === "No" ? 0 : formData.kids,
        kidsLivingStatus:
          formData.haveKids === "No" ? "" : formData.kidsLivingStatus,
        wantKids: formData.wantKids,
        currentResidence: formData.currentResidence,
        citizenOf: formData.citizenOf,
        motherTongue: formData.motherTongue,
        spokenLanguages: formData.spokenLanguages,

        // Religious Information
        subCaste: formData.subCaste,
        raasi: formData.raasi,
        placeOfBirth: formData.placeOfBirth,
        dateOfBirth: formData.dateOfBirth,
        timeOfBirth: formData.timeOfBirth,
        manglik: formData.manglik,
        manglikType: formData.manglik === "No" ? null : formData.manglikType,

        // Education/Employment Information
        education: formData.education,
        educationDetails: formData.educationDetails,
        additionalEducationInfo: formData.additionalEducationInfo,
        employment: formData.employment,
        occupation: formData.occupation,
        additionalEmploymentInfo: formData.additionalEmploymentInfo,
        currency: formData.currency,
        salary: formData.salary,
        salaryType: formData.salaryType,

        // General Information
        smoking: formData.smoking,
        tobacco: formData.tobacco,
        drinking: formData.drinking,
        drugs: formData.drugs,
        diet: formData.diet,
        height: formData.height,
        bodyType: formData.bodyType,
        disability: formData.disability,

        // Family Details
        familyValues: formData.familyValues,
        familyType: formData.familyType,
        familyStatus: formData.familyStatus,
        fathersName: formData.fathersName,
        fathersOccupation: formData.fathersOccupation,
        mothersName: formData.mothersName,
        mothersOccupation: formData.mothersOccupation,
        brothers: formData.brothers,
        brothersMarital: formData.brothersMarital,
        sisters: formData.sisters,
        sistersMarital: formData.sistersMarital,
        nativePlace: formData.nativePlace,

        contact: contactValues,

        aboutSelf: formData.aboutSelf,
        requirements: formData.requirements,
        profileImages,
        createdAt: Timestamp.now().toDate(),
        createdBy: user.displayName,
        userName: userName(formData.gender),
        userId: user.uid,
        profileCompleted: true,
      };

      const userRef = doc(db, "users", user.uid);
      // setDoc(profileRef,  profileDoc)
      updateDoc(userRef, profileDoc)
        .then(() => {
          updateProfile(auth.currentUser, {
            displayName: formData.firstName + " " + formData.lastName,
          });
          setLoading(false);
          setProgress(0);
          message.success(`Your profile created successfully.`, 2);
          navigate("/");
        })
        .catch((err) => {
          message.error(`Profile could not be created.`, 2);
        });

      setTimeout(() => {
        form.resetFields();
        setLoading(false);
      }, 1000);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <Title
        justify="center"
        align="middle"
        level={3}
        style={{ marginTop: "10px" }}
      >
        Create Profile
      </Title>
      <Spin spinning={loading}>
        <Form
          form={form}
          name="create-profile"
          layout="vertical"
          autoComplete="off"
          onFinish={handleFinish}
        >
          <>
            {current === 0 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Basic Information</Title>}>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="First Name"
                            name="firstName"
                            rules={[
                              {
                                required: true,
                                message: "Please input First Name",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="firstName"
                              placeholder="First Name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Last Name"
                            name="lastName"
                            rules={[
                              {
                                required: true,
                                message: "Please input Last Name",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="lastName"
                              placeholder="Last Name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            rules={[
                              {
                                required: true,
                                message: "Your gender",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="gender"
                              onChange={handleRadio}
                            >
                              <Radio value="Male">
                                {" "}
                                <Space>
                                  <img src={maleLogo} width="20px" alt="Male" />
                                  Male
                                </Space>
                              </Radio>
                              <Radio value="Female">
                                {" "}
                                <Space>
                                  <img
                                    src={femaleLogo}
                                    width="20px"
                                    alt="Female"
                                  />
                                  Female
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marital Status"
                            name="maritalStatus"
                            rules={[
                              {
                                required: true,
                                message: "Your marital status",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("maritalStatus")}
                              placeholder="Marital Status"
                              showSearch
                              options={jsonData.maritals}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Do you have Kids"
                            name="haveKids"
                            rules={[
                              {
                                required: true,
                                message: "Have kids?",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="haveKids"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes">
                                {" "}
                                <Space>
                                  <img src={kidsLogo} width="20px" alt="Kids" />
                                  Yes
                                </Space>
                              </Radio>
                              <Radio value="No">
                                {" "}
                                <Space>
                                  <img
                                    src={noKidsLogo}
                                    width="20px"
                                    alt="No Kids"
                                  />
                                  No
                                </Space>{" "}
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={5} xl={5}>
                          <Form.Item
                            label="Kids"
                            name="kids"
                            rules={[
                              {
                                required:
                                  formData.haveKids === "Yes" ? true : false,
                                message: "kids?",
                              },
                            ]}
                          >
                            <InputNumber
                              placeholder="No of Kids"
                              disabled={
                                formData.haveKids === "No" ? true : false
                              }
                              name="Kids"
                              onChange={handleNumberInput("kids")}
                              value={0}
                              min={0}
                              max={10}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={7} xl={7}>
                          <Form.Item
                            label="Kids Living"
                            name="kidsLivingStatus"
                            rules={[
                              {
                                required:
                                  formData.haveKids === "Yes" ? true : false,
                                message: "Kids living?",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Kids Living Status"
                              disabled={
                                formData.haveKids === "No" ? true : false
                              }
                              onChange={handleSelect("kidsLivingStatus")}
                              options={jsonData.kidsLivingStatus}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Current residence"
                            name="currentResidence"
                          >
                            <Input
                              name="currentResidence"
                              placeholder="Residing city"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Citizen of" name="citizenOf">
                            <Select
                              onChange={handleSelect("citizenOf")}
                              placeholder="Citizen Of"
                              showSearch
                              options={jsonData.currencies}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={8}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="MotherTongue"
                            name="motherTongue"
                            rules={[
                              {
                                required: true,
                                message: "Your mother tongue",
                              },
                            ]}
                          >
                            <Select
                              onChange={handleSelect("motherTongue")}
                              placeholder="Mother Tongue"
                              showSearch
                              options={jsonData.languages}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Languages you speak"
                            name="spokenLanguages"
                          >
                            <Select
                              mode="multiple"
                              onChange={handleSelect("spokenLanguages")}
                              placeholder="Spoken Languages"
                              showSearch
                              options={jsonData.languages}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Text size="small" italic type="secondary">
                          * Please note Gender, have kids, kids and kids living
                          cannot be changed later.
                        </Text>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 1 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card
                      title={<Title level={5}>Religious Information</Title>}
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Sub Caste"
                            name="subCaste"
                            rules={[
                              {
                                required: true,
                                message: "Subcaste please",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Sub Caste"
                              onChange={handleSelect("subCaste")}
                              options={jsonData.subcaste}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Zodia Sign" name="raasi">
                            <Select
                              showSearch
                              onChange={handleSelect("raasis")}
                              options={jsonData.raasis}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Place of Birth"
                            name="placeOfBirth"
                            rules={[
                              {
                                required: true,
                                message: "Please input Place of birth",
                              },
                            ]}
                          >
                            <Input
                              name="placeOfBirth"
                              placeholder="Place of Birth"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Date of Birth"
                            name="dateOfBirth"
                            rules={[
                              {
                                validator(_, value) {
                                  console.log("value ", value, value === null);
                                  if (value === null) {
                                    return Promise.reject(
                                      new Error("DOB Required")
                                    );
                                  } else if (value !== "" && getAge(value) < 18) {
                                    return Promise.reject(
                                      new Error("You must be 18+")
                                    );
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              },
                            ]}
                          >
                            <DatePicker
                              format={dateFormat}
                              name="dateOfBirth"
                              onChange={(date, dateString) =>
                                handleDateChange(
                                  date,
                                  dateString,
                                  "dateOfBirth"
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Time of Birth"
                            name="timeOfBirth"
                            rules={[
                              {
                                required: true,
                                message: "Time of Birth",
                              },
                            ]}
                          >
                            <TimePicker
                              name="timeOfBirth"
                              use12Hours
                              onChange={(time, timeString) =>
                                handleTimeChange(
                                  time,
                                  timeString,
                                  "timeOfBirth"
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={12} lg={12} xl={12}>
                          <Form.Item
                            label="Manglik"
                            name="manglik"
                            rules={[
                              {
                                required: true,
                                message: "Manglik",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="manglik"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={12} xl={12}>
                          <Form.Item
                            label="Manglik Type"
                            name="manglikType"
                            rules={[
                              {
                                required:
                                  formData.manglik === "Yes" ? true : false,
                                message: "Manglik Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Manglik Type"
                              disabled={formData.manglik === "No"}
                              onChange={handleSelect("manglikType")}
                              options={jsonData.manglikType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Text size="small" italic type="secondary">
                          * You cannot change these fields later. Please provide
                          accurate information
                        </Text>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 2 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card
                      title={
                        <Title level={5}>
                          Education/Employment Information
                        </Title>
                      }
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education"
                            name="education"
                            rules={[
                              {
                                required: true,
                                message: "Please input education",
                              },
                            ]}
                          >
                            <Select
                              placeholder="Education"
                              showSearch
                              onChange={handleSelect("education")}
                              options={jsonData.education}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education Details"
                            name="educationDetails"
                          >
                            <Select
                              showSearch
                              placeholder="Education Details"
                              onChange={handleSelect("educationDetails")}
                              options={jsonData.educationDetails}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={24} xl={24}>
                          <Form.Item
                            label="Additional Education Information"
                            name="additionalEducationInfo"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <TextArea
                              autoSize
                              placeholder="Additional information on your education.. college, achievements"
                              name="additionalEducationInfo"
                              onChange={handleChange}
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Employment"
                            name="employment"
                            rules={[
                              {
                                required: true,
                                message: "Please input employment",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Employment"
                              onChange={handleSelect("employment")}
                              options={jsonData.employment}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Occupation"
                            name="occupation"
                            rules={[
                              {
                                required: true,
                                message: "Please input occupation",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Occupation, Career"
                              onChange={handleSelect("occupation")}
                              options={jsonData.occupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={24} xl={24}>
                          <Form.Item
                            label="Additional Employemtt Information"
                            name="additionalEmploymentInfo"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <TextArea
                              autoSize
                              placeholder="Details about your employment.. job title, employer etc"
                              name="additionalEmploymentInfo"
                              onChange={handleChange}
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item label="Currency" name="currency">
                            <Select
                              showSearch
                              placeholder="Currency"
                              onChange={handleSelect("currency")}
                              options={jsonData.currencies}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={6} xl={6}>
                          <Form.Item label="Salary" name="salary">
                            <InputNumber
                              placeholder="Salary"
                              onChange={handleNumberInput("salary")}
                              style={{ width: "120px" }}
                              min={0}
                              max={9999999}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={6} xl={6}>
                          <Form.Item label="Frequency" name="salaryType">
                            <Select
                              showSearch
                              placeholder="Frequency"
                              onChange={handleSelect("salaryType")}
                              options={jsonData.salaryType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 3 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Family Details</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Fathers Occupation"
                            name="fathersOccupation"
                            rules={[
                              {
                                required: true,
                                message: "Fathers Occupation?",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Fathers Occupation"
                              onChange={handleSelect("fathersOccupation")}
                              options={jsonData.fathersOccupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Fathers Name"
                            name="fathersName"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="fathersName"
                              placeholder="Share Fathers name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Mothers Occupation"
                            name="mothersOccupation"
                            rules={[
                              {
                                required: true,
                                message: "Mothers Occupation?",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Mothers Occupation"
                              onChange={handleSelect("mothersOccupation")}
                              options={jsonData.mothersOccupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Mothers Name"
                            name="mothersName"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="mothersName"
                              placeholder="Mothers Name"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item label="Brothers" name="brothers">
                            <InputNumber
                              name="brothers"
                              placeholder="Brothers"
                              onChange={handleNumberInput("brothers")}
                              style={{ width: "120px" }}
                              min={0}
                              max={9}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Brothers Marital"
                            name="brothersMarital"
                          >
                            <InputNumber
                              name="brothersMarital"
                              placeholder="Brothers Marital"
                              onChange={handleNumberInput("brothersMarital")}
                              style={{ width: "120px" }}
                              min={0}
                              max={formData.brothers}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item label="Sisters" name="sisters">
                            <InputNumber
                              name="sisters"
                              placeholder="Sisters"
                              onChange={handleNumberInput("sisters")}
                              style={{ width: "120px" }}
                              min={0}
                              max={9}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Sisters Marital"
                            name="sistersMarital"
                          >
                            <InputNumber
                              name="sistersMarital"
                              placeholder="Sisters Marital"
                              onChange={handleNumberInput("sistersMarital")}
                              style={{ width: "120px" }}
                              min={0}
                              max={formData.sisters}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Values"
                            name="familyValues"
                            rules={[
                              {
                                required: true,
                                message: "Please input Family Values",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Family Values"
                              onChange={handleSelect("familyValues")}
                              options={jsonData.familyValues}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Status"
                            name="familyStatus"
                            rules={[
                              {
                                required: true,
                                message: "Please input Family Status",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Family Status"
                              onChange={handleSelect("familyStatus")}
                              options={jsonData.familyStatus}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Type"
                            name="familyType"
                            rules={[
                              {
                                required: true,
                                message: "Please pick Family Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Family Type"
                              onChange={handleSelect("familyType")}
                              options={jsonData.familyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Native Place"
                            name="nativePlace"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <Input
                              name="nativePlace"
                              placeholder="Your Native Place"
                              onChange={handleChange}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 4 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>General Information</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Smoking"
                            name="smoking"
                            rules={[
                              {
                                required: true,
                                message: "Smoking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="smoking"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Tobacco"
                            name="tobacco"
                            rules={[
                              {
                                required: true,
                                message: "Tobacco Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="tobacco"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Drinking"
                            name="drinking"
                            rules={[
                              {
                                required: true,
                                message: "Drinking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="drinking"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Socia lly"> Socially</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Drugs"
                            name="drugs"
                            rules={[
                              {
                                required: true,
                                message: "Drugs Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="drugs"
                              onChange={handleRadio}
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Sometimes"> Sometimes </Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Dietary Habits"
                            name="diet"
                            rules={[
                              {
                                required: true,
                                message: "Dietary Habits",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Dietary habits"
                              onChange={handleSelect("diet")}
                              options={jsonData.diet}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={5} xl={5}>
                          <Form.Item label="Height" name="height">
                            <Select
                              showSearch
                              placeholder="Height"
                              onChange={handleSelect("height")}
                              options={jsonData.height}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={4} xl={4}>
                          <Form.Item label="Weight" name="weight">
                            <InputNumber
                              placeholder="Weight"
                              name="weight"
                              value={0}
                              min={50}
                              max={400}
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={3} xl={3}>
                          <Form.Item label="(Lbs/Kg)" name="weightType">
                            <Select
                              onChange={handleSelect("weightType")}
                              name="weightType"
                              style={{ width: 60 }}
                            >
                              <Option value="Lbs">Lbs</Option>
                              <Option value="Kg">Kg</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Body Type"
                            name="bodyType"
                            rules={[
                              {
                                required: true,
                                message: "Body Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Body Type"
                              onChange={handleSelect("bodyType")}
                              options={jsonData.bodyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Disability" name="disability">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="disability"
                              onChange={handleRadio}
                            >
                              <Radio value="Normal"> Normal</Radio>
                              <Radio value="Physically Challenged">
                                Physically Challenged
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 5 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Contact Details</Title>}>
                      <Row gutter={8}>
                        <Form.List name="contacts">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                  }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    label="Contact Name"
                                    name={[name, "contactName"]}
                                    onChange={(event) =>
                                      handleContactsChange(
                                        name,
                                        event,
                                        "contactName"
                                      )
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing contact name",
                                      },
                                      {
                                        pattern: new RegExp(/^[a-zA-Z ]+$/i),
                                        message: "You can only use characters",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Contact Name" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Contact Number"
                                    name={[name, "contactNumber"]}
                                    onChange={(event) =>
                                      handleContactsChange(
                                        name,
                                        event,
                                        "contactNumber"
                                      )
                                    }
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing contact number",
                                      },
                                      {
                                        pattern: new RegExp(/^[0-9()+-]+$/i),
                                        message: "You can only use numbers",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Contact Number" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Private"
                                    name={[name, "private"]}
                                    valuePropName="checked"
                                  >
                                    <Switch
                                      style={{ marginLeft: "15px" }}
                                      size="small"
                                      name={[name, "private"]}
                                      onChange={(event) =>
                                        handleContactsChange(
                                          name,
                                          event,
                                          "private"
                                        )
                                      }
                                    />
                                  </Form.Item>
                                  <Form.Item>
                                    {name ? (
                                      <DeleteFilled
                                        onClick={() => {
                                          remove(name);
                                          removeContactFields(name);
                                        }}
                                      />
                                    ) : null}
                                  </Form.Item>
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                    addContactFields("add");
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Contact Details
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 6 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Upload Photos</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={24} xl={24}>
                          <Form.Item
                            name="profileImages"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload at least one photo",
                              },
                            ]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              listType="picture-card"
                              accept="image/*"
                            >
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button"
                              >
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                              </button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
                <Flex
                  type="flex"
                  justify="center"
                  align="middle"
                  gap="small"
                  wrap="center"
                >
                  <Button type="default" onClick={prevStep} htmlType="button">
                    previous
                  </Button>
                  <Button type="primary" onClick={nextStep} htmlType="button">
                    Next
                  </Button>
                </Flex>
              </>
            ) : current === 7 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>About Self</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Self"
                            name="aboutSelf"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about yourself",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z0-9'.,;: ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <TextArea
                              showCount
                              name="aboutSelf"
                              onChange={handleChange}
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          previous
                        </Button>
                        <Button
                          type="primary"
                          onClick={nextStep}
                          htmlType="button"
                        >
                          Next
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : current === 8 ? (
              <>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    <Card title={<Title level={5}>Match Requirements</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="Requirements"
                            name="requirements"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Your partner requirements",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z0-9'.,;: ]+$/i),
                                message: "You can only use characters",
                              },
                            ]}
                          >
                            <TextArea
                              showCount
                              name="requirements"
                              onChange={handleChange}
                              rows={4}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button
                          type="default"
                          onClick={prevStep}
                          htmlType="button"
                        >
                          Previous
                        </Button>
                        <Button type="primary" htmlType="submit">
                          Create Profile
                        </Button>
                      </Flex>
                    </Card>
                  </Col>
                </Row>
                <Row
                  gutter={[16, 16]}
                  type="flex"
                  justify="center"
                  align="middle"
                  style={{ marginBottom: 8 }}
                >
                  <Col className="gutter-row" xs={24} lg={12} xl={12}>
                    {progress === 0 ? null : <Progress percent={progress} />}
                  </Col>
                </Row>
              </>
            ) : (
              <>Invalid Step </>
            )}
          </>
        </Form>
      </Spin>
    </div>
  );
}
