import jsonData from "../data/gunas.json";
import jsonValues from "../data/data.json";
import moment from "moment";

function readingTime(article) {
  const text = article;
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time + " mins";
}

/* Nice function from https://stackoverflow.com/questions/73159795/how-can-i-calculate-age-from-date-of-birth-react-js */
function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString.toDate());
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function getDays(dateString) {
  const newStartDate = new Date(dateString.toDate());
  const newEndDate = new Date();

  const startDate = moment(newStartDate);
  const endDate = moment(newEndDate);
  const diff = endDate.diff(startDate);
  const diffDuration = moment.duration(diff);

  let result = diffDuration.days();

  if (result <= 0 ) {return 0}
  return result;
}

function getDateOfBirth() {
  const startDate = new Date("1960-01-01");
  const endDate = new Date("2005-12-31");
  const timeDiff = endDate.getTime() - startDate.getTime();
  const randomTime = Math.random() * timeDiff;
  const randomDate = new Date(startDate.getTime() + randomTime);

  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit"
  }).format(randomDate)
}

function userName(gender) {
  const userNamePrefix = gender === "Male" ? "G" : "B";
  return userNamePrefix + Math.random().toString(16).slice(9).toUpperCase();
}

function getGunaPoints(astro) {
  const gunas = jsonData.gunas;
  const guna = gunas
    .filter((guna) => guna.key === astro)
    .map((guna) => guna.value);
  return guna[0];
}

function getPadam(nakshatram) {
  switch (nakshatram) {
    case "Krittika":
      return true;
    case "Mrigasira":
      return true;
    case "Punarvasu":
      return true;
    case "Uttara Phalguni":
      return true;
    case "Chitra":
      return true;
    case "Vishakha":
      return true;
    case "Uttarashada":
      return true;
    case "Dhanishta":
      return true;
    case "Purvabhadra":
      return true;
    default:
      return false;
  }
}

var verbs, nouns, adjectives, adverbs, preposition;
nouns = ["bird", "clock", "boy", "plastic", "duck", "teacher", "old lady", "professor", "hamster", "dog"];
verbs = ["kicked", "ran", "flew", "dodged", "sliced", "rolled", "died", "breathed", "slept", "killed"];
adjectives = ["beautiful", "lazy", "professional", "lovely", "dumb", "rough", "soft", "hot", "vibrating", "slimy"];
adverbs = ["slowly", "elegantly", "precisely", "quickly", "sadly", "humbly", "proudly", "shockingly", "calmly", "passionately"];
preposition = ["down", "into", "up", "on", "upon", "below", "above", "through", "across", "towards"];

function sentence() {
  var rand1 = Math.floor(Math.random() * 10);
  var rand2 = Math.floor(Math.random() * 10);
  var rand3 = Math.floor(Math.random() * 10);
  var rand4 = Math.floor(Math.random() * 10);
  var rand5 = Math.floor(Math.random() * 10);
  var rand6 = Math.floor(Math.random() * 10);
  //                var randCol = [rand1,rand2,rand3,rand4,rand5];
  //                var i = randGen();
  var content = "The " + adjectives[rand1] + " " + 
    nouns[rand2] + " " + adverbs[rand3] + " " + verbs[rand4] + " because some " +
     nouns[rand1] + " " + adverbs[rand1] + " " + verbs[rand1] + " " + preposition[rand1] + " a " + adjectives[rand2] + " " + 
     nouns[rand5] + " which, became a " + adjectives[rand3] + ", " + adjectives[rand4] + " " + nouns[rand6] + ".";

  return content;
}


  function getRandomValue(text) {
    var random =
      jsonValues[text][Math.floor(Math.random() * jsonValues[text].length)];

    return random;
  }

  async function getImageUrl() {
    let response = await fetch(
      "https://source.unsplash.com/random/1920x1080/?wallpaper,landscape"
    ).then((data) => data.url);

    console.log(response);
  }

  function getLastName() {
    let last_name = [
      "Sharma",
      "Pandit",
      "Bhatt",
      "Chaturvedi",
      "Dubey",
      "Iyer",
      "Joshi",
      "Mishra",
      "Pandey",
      "Trivedi",
      "Dwivedi",
      "Kamath",
      "Karanth",
      "Namboothiris",
      "Shenoy",
      "Tiwari",
      "Tripathi",
      "Deshpande",
      "Hegde",
      "Mukherjee",
      "Bhaduri",
      "Bhagavati",
      "Bharadwaj",
    ];

    return last_name[Math.floor(Math.random() * last_name.length)];
  }

  function getBoyName() {
    let boy_name = [
      "Aarav",
      "Abhik",
      "Aditya",
      "Adyah",
      "Akshar",
      "Ambareesh",
      "Anagh",
      "Anshumat",
      "Ritwik",
      "Rehaan",
      "Rudr",
      "Vaman",
      "Vibhu",
      "Viraja",
      "Vishnu",
      "Vishwanath",
      "Vyas",
      "Sanu",
      "Shantanu",
      "Shivaya",
      "Rakshit",
      "Rushabh",
      "Partha",
      "Nahush",
      "Nilkanth",
      "Nimit",
      "Nishit",
      "Mohit",
      "Kashyap",
      "Karun",
      "Jatin",
      "Jiva",
      "Ishan",
      "Ijya",
      "Iravan",
      "Hemant",
      "Dhurya",
      "Dharesh",
      "Dhruv",
      "Dhurya",
      "Vansh",
      "Vihaan",
      "Varun",
      "Hrihaan",
      "Rishi",
      "Samrat",
      "Parth",
      "Aadi",
      "Neil",
      "Siddharth",
      "Siddhesh",
      "Gauraang",
    ];

    return boy_name[Math.floor(Math.random() * boy_name.length)];
  }

  function getGirlName() {
    let girl_name = [
      "Aadya",
      "Aastha",
      "Aditi",
      "Ati",
      "Dhriti",
      "Dipta",
      "Diva",
      "Harini",
      "Irya",
      "Ishani",
      "Jeevitha",
      "Madhvi",
      "Mahi",
      "Mahitha",
      "Mekhala",
      "Naima",
      "Nanda",
      "Nimi",
      "Nirja",
      "Nishi",
      "Nitha",
      "Nivritti",
      "Pari",
      "Pradha",
      "Pradnya",
      "Pragya",
      "Pratjna",
      "Pratishtha",
      "Preet",
      "Pushti",
      "Risha",
      "Ruhi",
      "Rukma",
      "Sadhana",
      "Shambhavi",
      "Shubha",
      "Shuchi",
      "Subhadra",
      "Sudiksha",
      "Supreet",
      "Swadha",
      "SWasti",
      "Tripti",
      "Tushti",
      "Uditi",
      "Urvi",
      "Varenya",
      "Vibha",
    ];

    return girl_name[Math.floor(Math.random() * girl_name.length)];
  }

  function getDomain() {
    let domains = [
      "example.com",
      "faker.com",
      "mail.com",
      "yahoo.com",
      "hotmail.com",
      "icloud.com",
      "outlook.com",
    ];

    return domains[Math.floor(Math.random() * domains.length)];
  }

  export {
    readingTime,
    getAge,
    getDays,
    getDateOfBirth,
    userName,
    getGunaPoints,
    getImageUrl,
    getPadam,
    getRandomValue,
    getLastName,
    getBoyName,
    getGirlName,
    getDomain,
    sentence,
  };
