import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth, storage } from "../../firebaseConfig";
import { useUserData } from "../../hooks/useUsers";
import jsonData from "../../data/data.json";
import dayjs from "dayjs";
import maleLogo from "../../assets/images/icons8-man-in-tuxedo-48.png";
import femaleLogo from "../../assets/images/icons8-female-48.png";
import kidsLogo from "../../assets/images/icons8-kids-40.png";
import noKidsLogo from "../../assets/images/icons8-no-kids-50.png";
import { useNavigate } from "react-router-dom";
import {
  OrderedListOutlined,
  PlusOutlined,
  DeleteFilled,
} from "@ant-design/icons";
import {
  Flex,
  Drawer,
  Menu,
  Form,
  Card,
  Row,
  Col,
  Typography,
  Input,
  InputNumber,
  Radio,
  Select,
  Button,
  Space,
  Switch,
  message,
  DatePicker,
  Upload,
  Progress,
  Spin,
} from "antd";
import { v4 as uuidv4 } from "uuid";

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function EditProfile() {
  let navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [form] = Form.useForm();
  const [selectedMenuItem, setSelectedMenuItem] = useState("basic");
  const [user] = useAuthState(auth);
  const { loading: userDataLoading, userData } = useUserData(user?.uid);
  const [visible, setVisible] = React.useState(false);
  // const [formData, setFormData] = useState({});

  React.useEffect(() => {
    if (userDataLoading) return;
    if (userData && !userData.profileCompleted) {
      navigate("/add-profile");
    } else {
      // setFormData({
      //   haveKids: userData?.haveKids,
      //   kids: userData?.kids,
      //   kidsLivingStatus: userData?.kidsLivingStatus,
      // });
      return;
    }
  }, [user, userData, userDataLoading, navigate]);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  // const handleChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleRadio = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  // const handleSelect = (name) => (value) => {
  //   setFormData({ ...formData, [name]: value });
  // };

  // const handleNumberInput = (name) => (value) => {
  //   setFormData({ ...formData, [name]: value });
  // };

  const initialValues = [
    {
      name: ["profileId"],
      value: userData?.profileId,
    },
    {
      name: ["firstName"],
      value: userData?.firstName,
    },
    {
      name: ["lastName"],
      value: userData?.lastName,
    },
    {
      name: ["gender"],
      value: userData?.gender,
    },
    {
      name: ["maritalStatus"],
      value: userData?.maritalStatus,
    },
    {
      name: ["haveKids"],
      value: userData?.haveKids,
    },
    {
      name: ["wantKids"],
      value: userData?.wantKids,
    },
    {
      name: ["kids"],
      value: userData?.kids || 0,
    },
    {
      name: ["kidsLivingStatus"],
      value: userData?.kidsLivingStatus || "",
    },
    {
      name: ["currentResidence"],
      value: userData?.currentResidence,
    },
    {
      name: ["citizenOf"],
      value: userData?.citizenOf,
    },
    {
      name: ["motherTongue"],
      value: userData?.motherTongue,
    },
    {
      name: ["spokenLanguages"],
      value: userData?.spokenLanguages,
    },
    {
      name: ["currentResidence"],
      value: userData?.currentResidence,
    },
    {
      name: ["subCaste"],
      value: userData?.subCaste,
    },
    {
      name: ["raasi"],
      value: userData?.raasi,
    },
    {
      name: ["placeOfBirth"],
      value: userData?.placeOfBirth,
    },
    {
      name: ["dateOfBirth"],
      value: dayjs(userData?.dateOfBirth.toDate()),
    },
    {
      name: ["timeOfBirth"],
      value: userData?.timeOfBirth,
    },
    {
      name: ["manglik"],
      value: userData?.manglik,
    },
    {
      name: ["manglikType"],
      value: userData?.manglikType,
    },
    {
      name: ["education"],
      value: userData?.education,
    },
    {
      name: ["educationDetails"],
      value: userData?.educationDetails,
    },
    {
      name: ["additionalEducationInfo"],
      value: userData?.additionalEducationInfo,
    },
    {
      name: ["employment"],
      value: userData?.employment,
    },
    {
      name: ["occupation"],
      value: userData?.occupation,
    },
    {
      name: ["additionalEmploymentInfo"],
      value: userData?.additionalEmploymentInfo,
    },
    {
      name: ["currency"],
      value: userData?.currency,
    },
    {
      name: ["salary"],
      value: userData?.salary,
    },
    {
      name: ["salaryType"],
      value: userData?.salaryType,
    },
    {
      name: ["fathersOccupation"],
      value: userData?.fathersOccupation,
    },
    {
      name: ["fathersName"],
      value: userData?.fathersName,
    },
    {
      name: ["mothersOccupation"],
      value: userData?.mothersOccupation,
    },
    {
      name: ["mothersName"],
      value: userData?.mothersName,
    },
    {
      name: ["brothers"],
      value: userData?.brothers,
    },
    {
      name: ["brothersMarital"],
      value: userData?.brothersMarital,
    },
    {
      name: ["sisters"],
      value: userData?.sisters,
    },
    {
      name: ["sistersMarital"],
      value: userData?.sistersMarital,
    },
    {
      name: ["familyValues"],
      value: userData?.familyValues,
    },
    {
      name: ["familyStatus"],
      value: userData?.familyStatus,
    },
    {
      name: ["familyType"],
      value: userData?.familyType,
    },
    {
      name: ["nativePlace"],
      value: userData?.nativePlace,
    },
    {
      name: ["smoking"],
      value: userData?.smoking,
    },
    {
      name: ["tobacco"],
      value: userData?.tobacco,
    },
    {
      name: ["drinking"],
      value: userData?.drinking,
    },
    {
      name: ["marijuana"],
      value: userData?.marijuana,
    },
    {
      name: ["diet"],
      value: userData?.diet,
    },
    {
      name: ["height"],
      value: userData?.height,
    },
    {
      name: ["bodyType"],
      value: userData?.bodyType,
    },
    {
      name: ["disability"],
      value: userData?.disability,
    },
    {
      name: ["contact"],
      value: userData?.contact,
    },
    {
      name: ["profileImages"],
      value: userData?.profileImages,
    },
    {
      name: ["aboutSelf"],
      value: userData?.aboutSelf,
    },
    {
      name: ["requirements"],
      value: userData?.requirements,
    },
  ];

  const storageImage = async (img) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `/images/${Date.now()}-${uuidv4()}`);
      const uploadImage = uploadBytesResumable(storageRef, img.originFileObj);

      uploadImage.on(
        "state_changed",
        (snapshot) => {
          const progressPercent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progressPercent);
        },
        (error) => {
          reject(error);
        },
        () => {
          // Upload completed successfully, now we can get the download URL
          getDownloadURL(uploadImage.snapshot.ref).then((downloadURL) => {
            resolve({ uid: uuidv4(), url: downloadURL });
          });
        }
      );
    });
  };

  const handleFinish = async (values) => {
    var profileImages = [];
    if (selectedMenuItem === "photos") {
      profileImages = await Promise.all(
        [...values.profileImages].map((image) => {
          if (image.url) {
            return image;
          } else {
            return storageImage(image);
          }
        })
      ).catch((error) => {
        alert(error);
        return;
      });
    }

    setLoading(true);
    try {
      const profileDoc =
        selectedMenuItem === "basic"
          ? {
            profileId: values.profileId,
            firstName: values.firstName,
            lastName: values.lastName,
            gender: values.gender,
            maritalStatus: values.maritalStatus,
            haveKids: values.haveKids,
            wantKids: values.wantKids || "",
            kids: values.kids || 0,
            kidsLivingStatus: values.kidslivingStatus || "",
            currentResidence: values.currentResidence,
            citizenOf: values.citizenOf,
            motherTongue: values.motherTongue,
            spokenLanguages: values.spokenLanguages,
          }
          : selectedMenuItem === "religious"
            ? {
              subCaste: values.subCaste,
              raasi: values.raasi,
              manglik: values.manglik,
              manglikType: values.manglikType,
            }
            : selectedMenuItem === "education"
              ? {
                education: values.education,
                educationDetails: values.educationDetails,
                additionalEducationInfo: values.additionalEducationInfo,
                employment: values.employment,
                occupation: values.occupation,
                additionalEmploymentInfo: values.additionalEmploymentInfo,
                currency: values.currency,
                salary: values.salary,
                salaryType: values.salaryType,
              }
              : selectedMenuItem === "family"
                ? {
                  familyValues: values.familyValues,
                  familyStatus: values.familyStatus,
                  familyType: values.familyType,
                  fathersOccupation: values.fathersOccupation,
                  fathersName: values.fathersName,
                  mothersOccupation: values.mothersOccupation,
                  mothersName: values.mothersName,
                  brothers: values.brothers,
                  brothersMarital: values.brothersMarital,
                  sisters: values.sisters,
                  sistersMarital: values.sistersMarital,
                  nativePlace: values.nativePlace,
                }
                : selectedMenuItem === "general"
                  ? {
                    smoking: values.smoking,
                    tobacco: values.tobacco,
                    drinking: values.drinking,
                    drugs: values.drugs,
                    diet: values.diet,
                    height: values.height,
                    weight: values.weight,
                    weightType: values.weightType,
                    bodyType: values.bodyType,
                    disability: values.disability,
                  }
                  : selectedMenuItem === "contact"
                    ? {
                      contact: values.contact,
                    }
                    : selectedMenuItem === "photos"
                      ? {
                        profileImages: profileImages,
                      }
                      : selectedMenuItem === "aboutSelf"
                        ? {
                          aboutSelf: values.aboutSelf,
                        }
                        : selectedMenuItem === "aboutPartner"
                          ? {
                            requirements: values.requirements,
                          }
                          : null;

      const userRef = doc(db, "users", user.uid);
      updateDoc(userRef, profileDoc)
        .then(() => {
          setLoading(false);
          message.success(`Information updated successfully.`, 2);
        })
        .catch((err) => {
          message.error(`Profile could not be created.`, 2);
        });

      setTimeout(() => {
        setLoading(false);
        setProgress(0);
      }, 1000);
    } catch (error) { }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="container">
      <Spin spinning={loading}>
        <Row>
          <Col xs={24} lg={6} xl={6}>
            <div className="mobileHidden">
              <div id="feature" className="block">
                <div className="titleHolder">
                  <h3>Tabs</h3>
                </div>

                <Menu
                  selectedKeys={selectedMenuItem}
                  mode="vertical"
                  onClick={(e) => setSelectedMenuItem(e.key)}
                >
                  <Menu.Item key="basic">Basic Information</Menu.Item>
                  <Menu.Item key="religious">Religious Information</Menu.Item>
                  <Menu.Item key="education">Education Information</Menu.Item>
                  <Menu.Item key="family">Family Information</Menu.Item>
                  <Menu.Item key="general">General Information</Menu.Item>
                  <Menu.Item key="contact">Contact Information</Menu.Item>
                  <Menu.Item key="photos">Photos Information</Menu.Item>
                  <Menu.Item key="aboutSelf">About Self Information</Menu.Item>
                  <Menu.Item key="aboutPartner">
                    About Partner Information
                  </Menu.Item>
                </Menu>
              </div>
            </div>
            <div className="mobileVisible">
              <Button type="primary" onClick={showDrawer}>
                <OrderedListOutlined />
              </Button>
              <Drawer placement="left" onClose={onClose} open={visible}>
                <div className="titleHolder">
                  <h3>Tabs</h3>
                </div>
                <Col sm={24}>
                  <Menu
                    selectedKeys={selectedMenuItem}
                    mode="vertical"
                    onClick={(e) => setSelectedMenuItem(e.key)}
                  >
                    <Menu.Item key="basic">Basic Information</Menu.Item>
                    <Menu.Item key="religious">Religious Information</Menu.Item>
                    <Menu.Item key="education">Education Information</Menu.Item>
                    <Menu.Item key="family">Family Information</Menu.Item>
                    <Menu.Item key="general">General Information</Menu.Item>
                    <Menu.Item key="contact">Contact Information</Menu.Item>
                    <Menu.Item key="photos">Photos Information</Menu.Item>
                    <Menu.Item key="aboutSelf">
                      About Self Information
                    </Menu.Item>
                    <Menu.Item key="aboutPartner">
                      About Partner Information
                    </Menu.Item>
                  </Menu>
                </Col>
              </Drawer>
            </div>
          </Col>
          <Col xs={24} lg={18} xl={18}>
            <div id="feature" className="block">
              <div className="titleHolder">
                <h3>Edit Profile</h3>
              </div>
              {selectedMenuItem === "basic" ? (
                <Col xs={24} lg={18} xl={18}>
                <Form
                  layout="vertical"
                  name="basic"
                  wrapperCol={{
                    span: 24,
                  }}
                  style={{
                    maxWidth: 600,
                  }}
                  form={form}
                  fields={initialValues}
                  onFinish={handleFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                >
                  <Card title={<Title level={5}>Basic Information</Title>}>
                    <Row gutter={8}>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item name="profileId" hidden>
                          <Input hidden name="profileId" />
                        </Form.Item> 
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          rules={[
                            {
                              required: true,
                              message: "Please input First Name",
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: "You can only use characters"
                            }
                          ]}
                        >
                          <Input name="firstName" placeholder="First Name" />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          rules={[
                            {
                              required: true,
                              message: "Please input Last Name",
                            },
                            {
                              pattern: new RegExp(/^[a-zA-Z ]+$/i),
                              message: "You can only use characters"
                            }
                          ]}
                        >
                          <Input name="lastName" placeholder="Last Name" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item label="Gender" name="gender">
                          <Radio.Group
                            disabled
                            optionType="button"
                            buttonStyle="solid"
                            name="gender"
                          >
                            <Radio value="Male" name="gender">
                              {" "}
                              <Space>
                                <img src={maleLogo} width="20px" alt="Male" />
                                Male
                              </Space>
                            </Radio>
                            <Radio value="Female" name="gender">
                              {" "}
                              <Space>
                                <img
                                  src={femaleLogo}
                                  width="20px"
                                  alt="Female"
                                />
                                Female
                              </Space>{" "}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="Marital Status"
                          name="maritalStatus"
                          rules={[
                            {
                              required: true,
                              message: "Your marital status",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Marital Status"
                            showSearch
                            options={jsonData.maritals}
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="Do you have Kids"
                          name="haveKids"
                          shouldUpdate={(prevValues, currentValues) => prevValues.haveKids !== currentValues.haveKids}
                        >
                          <Radio.Group
                            disabled
                            optionType="button"
                            buttonStyle="solid"
                            name="haveKids"
                          >
                            <Radio value="Yes">
                              {" "}
                              <Space>
                                <img src={kidsLogo} width="20px" alt="Kids" />
                                Yes
                              </Space>
                            </Radio>
                            <Radio value="No">
                              {" "}
                              <Space>
                                <img
                                  src={noKidsLogo}
                                  width="20px"
                                  alt="No Kids"
                                />
                                No
                              </Space>{" "}
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={12} lg={5} xl={5}>
                        <Form.Item
                          label="Kids"
                          name="kids"
                          // dependencies={['haveKids']}
                          // rules={[
                          //   ({ getFieldValue }) => ({
                          //     validator(_, value) {
                          //       console.log(getFieldValue('haveKids'), value);
                          //       if ( (getFieldValue('haveKids') === "Yes" && value > 0 ) || (getFieldValue('haveKids') === "No") ) {
                          //         return Promise.resolve();
                          //       }
                          //       return Promise.reject(new Error('Required'));
                          //     },
                          //   }),
                          // ]}
                        >
                          <InputNumber
                            disabled
                            name="Kids"
                            value={0}
                            min={0}
                            max={10}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={12} lg={7} xl={7}>
                        <Form.Item
                          label="Kids Living"
                          name="kidsLivingStatus"
                        >
                          <Select
                            disabled
                            showSearch
                            options={jsonData.kidsLivingStatus}
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="Current residence"
                          name="currentResidence"
                        >
                          <Input
                            name="currentResidence"
                            placeholder="Residing city"
                          />
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item label="Citizen of" name="citizenOf">
                          <Select
                            placeholder="Citizen Of"
                            showSearch
                            options={jsonData.currencies}
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="MotherTongue"
                          name="motherTongue"
                          rules={[
                            {
                              required: true,
                              message: "Your mother tongue",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Mother Tongue"
                            showSearch
                            options={jsonData.languages}
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                      <Col className="gutter-row" xs={24} lg={12} xl={12}>
                        <Form.Item
                          label="Languages you speak"
                          name="spokenLanguages"
                        >
                          <Select
                            mode="multiple"
                            placeholder="Spoken Languages"
                            showSearch
                            options={jsonData.languages}
                            allowClear
                          ></Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Flex
                      type="flex"
                      justify="center"
                      align="middle"
                      gap="small"
                      wrap="center"
                    >
                      <Button type="primary" htmlType="submit">
                        Update Basic
                      </Button>
                    </Flex>
                  </Card>
                </Form>
              </Col>
              ) : selectedMenuItem === "religious" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="religious"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card
                      title={<Title level={5}>Religious Information</Title>}
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Sub Caste"
                            name="subCaste"
                          >
                            <Select
                              disabled
                              showSearch
                              options={jsonData.subcaste}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Raasi" name="raasi">
                            <Select
                              showSearch
                              placeholder="Zodia Sign/Raasi"
                              options={jsonData.raasis}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Place of Birth"
                            name="placeOfBirth"
                          >
                            <Input
                              disabled
                              name="placeOfBirth"
                              placeholder="Place of Birth"
                            />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={12} lg={6} xl={6}>
                          <Form.Item label="Date of Birth" name="dateOfBirth">
                            <DatePicker disabled name="dateOfBirth" />
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={12} lg={6} xl={6}>
                          <Form.Item label="Time of Birth" name="timeOfBirth">
                            <Input disabled name="timeOfBirth" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={12} lg={12} xl={12}>
                          <Form.Item label="Manglik" name="manglik">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="manglik"
                              disabled
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={12} xl={12}>
                          <Form.Item label="Manglik Type" name="manglikType">
                            <Select
                              disabled
                              showSearch
                              options={jsonData.doshamType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Religious
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "education" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="education"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card
                      title={
                        <Title level={5}>
                          Education/Employment Information
                        </Title>
                      }
                    >
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education"
                            name="education"
                            rules={[
                              {
                                required: true,
                                message: "Please input education",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.education}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Education Details"
                            name="educationDetails"
                          >
                            <Select
                              showSearch
                              options={jsonData.educationDetails}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={24} xl={24}>
                          <Form.Item
                            label="Additional Education Information"
                            name="additionalEducationInfo"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z,.;' ]+$/i),
                                message: "You can only use characters"
                              }

                            ]}
                          >
                            <TextArea
                              autoSize
                              placeholder="Additional Educational information you want to share"
                              name="additionalEducationInfo"
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Employment"
                            name="employment"
                            rules={[
                              {
                                required: true,
                                message: "Please input employment",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.employment}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Occupation"
                            name="occupation"
                            rules={[
                              {
                                required: true,
                                message: "Please input occupation",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.occupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={24} xl={24}>
                          <Form.Item
                            label="Additional Employemtt Information"
                            name="additionalEmploymentInfo"
                            rules={[
                              {
                                pattern: new RegExp(/^[a-zA-Z,.;' ]+$/i),
                                message: "You can only use characters"
                              }

                            ]}
                          >
                            <TextArea
                              placeholder="Additional Employment Details you want to share"
                              autoSize
                              name="additionalEmploymentInfo"
                              rows={1}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Currency"
                            name="currency"
                            rules={[
                              {
                                required: true,
                                message: "Pick a currency",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.currencies}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={6} xl={6}>
                          <Form.Item label="Salary" name="salary">
                            <InputNumber
                              style={{ width: "120px" }}
                              min={0}
                              max={9999999}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} lg={6} xl={6}>
                          <Form.Item label="Frequency" name="salaryType">
                            <Select
                              showSearch
                              options={jsonData.salaryType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Employment
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "family" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="family"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>Family Details</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Fathers Occupation"
                            name="fathersOccupation"
                            rules={[
                              {
                                required: true,
                                message: "Fathers Occupation?",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.fathersOccupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Fathers Name" name="fathersName">
                            <Input name="fathersName" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Mothers Occupation"
                            name="mothersOccupation"
                            rules={[
                              {
                                required: true,
                                message: "Mothers Occupation?",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.mothersOccupation}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Mothers Name" name="mothersName">
                            <Input name="mothersName" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={12}>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item label="Brothers" name="brothers">
                            <InputNumber
                              disabled
                              name="brothers"
                              style={{ width: "120px" }}
                              min={0}
                              max={9}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Brothers Marital"
                            name="brothersMarital"
                          >
                            <InputNumber
                              disabled
                              name="brothersMarital"
                              style={{ width: "120px" }}
                              min={0}
                              max={10}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item label="Sisters" name="sisters">
                            <InputNumber
                              disabled
                              name="sisters"
                              style={{ width: "120px" }}
                              min={0}
                              max={9}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={12} lg={6} xl={6}>
                          <Form.Item
                            label="Sisters Marital"
                            name="sistersMarital"
                          >
                            <InputNumber
                              disabled
                              name="sistersMarital"
                              style={{ width: "120px" }}
                              min={0}
                              max={10}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Values"
                            name="familyValues"
                            rules={[
                              {
                                required: true,
                                message: "Please input Family Values",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.familyValues}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Status"
                            name="familyStatus"
                            rules={[
                              {
                                required: true,
                                message: "Please input Family Status",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.familyStatus}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Family Type"
                            name="familyType"
                            rules={[
                              {
                                required: true,
                                message: "Please pick Family Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.familyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Native Place"
                            name="nativePlace"
                          >
                            <Input name="nativePlace" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Family
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "general" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="general"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>General Information</Title>}>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Smoking"
                            name="smoking"
                            rules={[
                              {
                                required: true,
                                message: "Smoking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="smoking"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Tobacco"
                            name="tobacco"
                            rules={[
                              {
                                required: true,
                                message: "Tobacco Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="tobacco"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Occasional"> Occasional</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Drinking"
                            name="drinking"
                            rules={[
                              {
                                required: true,
                                message: "Drinking Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="drinking"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Socially"> Socially</Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Marijuana"
                            name="marijuana"
                            rules={[
                              {
                                required: true,
                                message: "Marijuana Habits",
                              },
                            ]}
                          >
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="marijuana"
                            >
                              <Radio value="Yes"> Yes</Radio>
                              <Radio value="Sometimes"> Sometimes </Radio>
                              <Radio value="No"> No </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Dietary Habits"
                            name="diet"
                            rules={[
                              {
                                required: true,
                                message: "Dietary Habits",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.diet}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={6} xl={6}>
                          <Form.Item label="Height" name="height">
                            <Select
                              showSearch
                              options={jsonData.height}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={6}>
                        <Form.Item label="Weight" name="weight">
                          <InputNumber
                            name="weight"
                            value={0}
                            min={50}
                            max={400}
                          />
                        </Form.Item>
                        <Form.Item label="Measured" name="weightType">
                          <Select
                            label="(Meaured)"
                            name="weightType"
                            style={{ width: 60 }}
                          >
                            <Option value="Lbs">Lbs</Option>
                            <Option value="Kg">Kg</Option>
                          </Select>
                        </Form.Item>
                      </Row>
                      <Row gutter={6}>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item
                            label="Body Type"
                            name="bodyType"
                            rules={[
                              {
                                required: true,
                                message: "Body Type",
                              },
                            ]}
                          >
                            <Select
                              showSearch
                              options={jsonData.bodyType}
                              allowClear
                            ></Select>
                          </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} lg={12} xl={12}>
                          <Form.Item label="Disability" name="disability">
                            <Radio.Group
                              optionType="button"
                              buttonStyle="solid"
                              name="disability"
                            >
                              <Radio value="Normal"> Normal</Radio>
                              <Radio value="Physically Challenged">
                                Physically Challenged
                              </Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update General
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "contact" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>Contact Details</Title>}>
                      <Row gutter={8}>
                        <Form.List name="contact">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }) => (
                                <Space
                                  key={key}
                                  style={{
                                    display: "flex",
                                    marginBottom: 8,
                                  }}
                                  align="baseline"
                                >
                                  <Form.Item
                                    {...restField}
                                    label="Contact Name"
                                    name={[name, "contactName"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing contact name",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Contact Name" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Contact Number"
                                    name={[name, "contactNumber"]}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Missing contact number",
                                      },
                                    ]}
                                  >
                                    <Input placeholder="Contact Number" />
                                  </Form.Item>
                                  <Form.Item
                                    {...restField}
                                    label="Private"
                                    name={[name, "private"]}
                                    valuePropName="checked"
                                    initialValue={true}
                                  >
                                    <Switch
                                      style={{ marginLeft: "15px" }}
                                      size="small"
                                      name={[name, "private"]}
                                    />
                                  </Form.Item>
                                  <Form.Item>
                                    {name ? (
                                      <DeleteFilled
                                        onClick={() => {
                                          remove(name);
                                        }}
                                      />
                                    ) : null}
                                  </Form.Item>
                                </Space>
                              ))}
                              <Form.Item>
                                <Button
                                  type="dashed"
                                  onClick={() => {
                                    add();
                                  }}
                                  block
                                  icon={<PlusOutlined />}
                                >
                                  Add Contact Details
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Contact Details
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "photos" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>Photos</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            name="profileImages"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[
                              {
                                required: true,
                                message: "Please upload one photo",
                              },
                            ]}
                          >
                            <Upload
                              beforeUpload={() => false}
                              listType="picture-card"
                              accept="image/*"
                            >
                              <button
                                style={{
                                  border: 0,
                                  background: "none",
                                }}
                                type="button"
                              >
                                <PlusOutlined />
                                <div
                                  style={{
                                    marginTop: 8,
                                  }}
                                >
                                  Upload
                                </div>
                              </button>
                            </Upload>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update Photos
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "aboutSelf" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>About Self</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Self"
                            name="aboutSelf"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about yourself",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z0-9'.,; ]+$/i),
                                message: "You can only use characters"
                              }
                            ]}
                          >
                            <TextArea name="aboutSelf" rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update About Self
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : selectedMenuItem === "aboutPartner" ? (
                <Col xs={24} lg={18} xl={18}>
                  <Form
                    layout="vertical"
                    name="basic"
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    form={form}
                    fields={initialValues}
                    onFinish={handleFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Card title={<Title level={5}>About Partner</Title>}>
                      <Row gutter={6}>
                        <Col span={24}>
                          <Form.Item
                            label="About Partner"
                            name="requirements"
                            rules={[
                              {
                                required: true,
                                min: 20,
                                message: "Write a little about partner",
                              },
                              {
                                pattern: new RegExp(/^[a-zA-Z0-9'.,; ]+$/i),
                                message: "You can only use characters"
                              }
                            ]}
                          >
                            <TextArea name="requirements" rows={4} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Flex
                        type="flex"
                        justify="center"
                        align="middle"
                        gap="small"
                        wrap="center"
                      >
                        <Button type="primary" htmlType="submit">
                          Update About Partner
                        </Button>
                      </Flex>
                    </Card>
                  </Form>
                </Col>
              ) : null}
              <Row
                gutter={[16, 16]}
                type="flex"
                justify="center"
                align="middle"
                style={{ marginBottom: 8 }}
              >
                <Col className="gutter-row" xs={24} lg={12} xl={12}>
                  {progress === 0 ? null : <Progress percent={progress} />}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default EditProfile;
