import React from 'react';
import { Row, Col } from 'antd';
import { SecurityScanOutlined,  DesktopOutlined, DatabaseOutlined } from "@ant-design/icons";


const items = [
  {
    key: '1',
    icon: <DesktopOutlined />,
    title: 'A Platform exclusively for Sikhs',
    content: 'We take pride in being a community-driven platform, created by individuals who understand the nuances of Sikh traditions.',
  },
  {
    key: '2',
    icon: <SecurityScanOutlined />,
    title: 'Privacy and Security',
    content: 'We understand the importance of privacy and security in the context of matrimonial searches and robust security measures',
  },
  {
    key: '3',
    icon: <DatabaseOutlined />,
    title: 'Diverse Profiles, Shared Values',
    content: "Our platform hosts a diverse array of profiles within the Sikh community, allowing you to explore a wide range of potential matches."
  }
]

function AppAbout() {
  return (
    <>
     <div id="about" className="block aboutBlock">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>About Us</h2>
        </div>
        <div className="contentHolder">
          <p>
          Welcome to Sikhmatches, a sacred space designed exclusively for Sikh communities to embark on the 
          beautiful journey of finding a life partner. We understand the sanctity and significance of marriage in Sikh culture, 
          and our platform is crafted with utmost care to honor these traditions. </p>

          <p>
          At Sikhmatches, we celebrate the rich cultural heritage of Sikhs, acknowledging the values, traditions, and principles 
          that make Sikh communities unique. Established with a deep respect for the sanctity of marriage, our platform is committed 
          to fostering meaningful connections within the Sikh community.,
          </p>

        </div>
        <Row gutter={[16, 16]}>   
          {items.map(item => {
            return (
              <Col md={{ span: 8 }} key={item.key}>
                <div className="content">
                  <div className="icon">
                    {item.icon}
                  </div>
                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              </Col>
            );
          })}
        </Row>
        <p>
        We invite you to join our community of like-minded Sikh individuals seeking a life partner. Sikhmatches is more than just a platform; it's a community 
        that understands the sacred nature of marriage within Sikh culture.
        </p>
        <p>
        Embark on your journey with Sikhmatches, where tradition meets technology, and where meaningful connections are nurtured with care and respect.
        </p>
      </div>
    </div>  
    </>
   
  );
}

export default AppAbout;