import React from 'react';

import image1 from '../assets/images/community.jpg';
import image2 from '../assets/images/filters.jpg';
import image4 from '../assets/images/mobile.jpg';

import { Row, Col } from 'antd';
import { Card } from 'antd';
const { Meta } = Card;

function AppFeature() {
  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container-fluid">
        <div className="titleHolder">
          <h2>Key Features and Benefits</h2>
        </div>
        <Row gutter={[16, 16]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Community Specific Profiles"
                style={{ height: "100%", width: "100%" }}
                src={image1}
              />
            </div>}
            >
              <Meta title="Community-specific Profiles" description="Dedicated profiles for Sikh individuals, allowing users to specify their subcaste, family background, and other relevant details" />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
                <img
                  alt="Subcaste Filters"
                  style={{ height: "100%", width: "100%" }}
                  src={image2}
                />
              </div>}
            >
              <Meta title="Subcaste Filters" description="Advanced search filters that enable users to narrow down their search based on specific subcastes within the Sikh community." />
            </Card>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Card
              hoverable
              cover={<div style={{ overflow: "hidden", height: "240px" }}>
              <img
                alt="Mobile App Compatibity"
                style={{ height: "100%", width: "100%" }}
                src={image4}
              />
            </div>}
            >
              <Meta title="Mobile App Compatibility" description="Responsive design so it can be easily accessable from their smartphones, ensuring flexibility and ease of use" />
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AppFeature;