import {
  collection,
  onSnapshot,
  query,
  where,
  limit,
  orderBy,
  startAfter,

} from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { auth, db } from "../../firebaseConfig";
import { Row, Col, Typography, Spin, Button } from "antd";
import { Empty, Card, Badge } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { useUserData } from "../../hooks/useUsers";
// import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { getAge } from "../../lib/utils";
const { Meta } = Card;

function getDate(years) {
  var date = new Date();
  date.setFullYear(date.getFullYear() - years);
  return date;
}

export default function Profiles(params) {
  const [loading, setLoading] = useState(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [profileCount, setProfileCount] = useState(0);
  const [lastProfile, setLastProfile] = useState(null);
  const [user] = useAuthState(auth);
  const { userData } = useUserData(user?.uid);

  function queryParams(q, params) {
    if (params.searchCriteria.maritalStatus)
        q = query(
          q,
          where("maritalStatus", "==", params.searchCriteria.maritalStatus),
          orderBy("maritalStatus")
        );
      if (params.searchCriteria.subCaste)
        q = query(
          q,
          where("subCaste", "==", params.searchCriteria.subCaste),
          orderBy("subCaste")
        );
      if (params.searchCriteria.haveKids)
        q = query(
          q,
          where("haveKids", "==", params.searchCriteria.haveKids),
          orderBy("haveKids")
        );
      if (params.searchCriteria.wantKids)
        q = query(
          q,
          where("wantKids", "==", params.searchCriteria.wantKids),
          orderBy("wantKids")
        );
      if (params.searchCriteria.motherTongue)
        q = query(
          q,
          where("motherTongue", "==", params.searchCriteria.motherTongue),
          orderBy("motherTongue")
        );
      if (params.searchCriteria.education)
        q = query(
          q,
          where("education", "==", params.searchCriteria.education),
          orderBy("education")
        );
      if (params.searchCriteria.employment)
        q = query(
          q,
          where("employment", "==", params.searchCriteria.employment),
          orderBy("employment")
        );
      if (params.searchCriteria.smoking)
        q = query(
          q,
          where("smoking", "==", params.searchCriteria.smoking),
          orderBy("smoking")
        );
      if (params.searchCriteria.drinking)
        q = query(
          q,
          where("drinking", "==", params.searchCriteria.drinking),
          orderBy("drinking")
        );
      if (params.searchCriteria.minAge)
        q = query(
          q,
          where("dateOfBirth", "<=", getDate(params.searchCriteria.minAge)),
        );
      if (params.searchCriteria.maxAge)
        q = query(
          q,
          where("dateOfBirth", ">=", getDate(params.searchCriteria.maxAge + 1)),
        );

    return q;
  }

  useEffect(() => {
    if (userData) {
      const getOppositeGender =
        userData?.gender === "Male" ? "Female" : "Male";

      const profileRef = collection(db, "users");
      let q = query(
        profileRef,
        where("profileCompleted", "==", true),
        where("gender", "==", getOppositeGender),
        orderBy("dateOfBirth", "desc"),
        limit(20)
      );
      
      q = queryParams(q, params);

      onSnapshot(q, (snapshot) => {
        const profiles = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastProfile = snapshot.docs[snapshot.docs.length - 1];
        setLastProfile(lastProfile);
        setProfiles(profiles);
        setProfileCount(profiles.length);
        setLoading(false);
      });
    }
  }, [params, userData, user]);

  const loadMoreProfiles = () => {
    setLoadMoreLoading(true);
    const getOppositeGender = userData?.gender === "Male" ? "Female" : "Male";

    const profileRef = collection(db, "users");

    let q = query(
      profileRef,
      where("profileCompleted", "==", true),
      where("gender", "==", getOppositeGender),
      orderBy("dateOfBirth", "desc"),
      limit(6),
      startAfter(lastProfile)
    );

    q = queryParams(q, params);

    onSnapshot(q, (snapshot) => {
      const moreProfiles = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        // .filter(item => !item.friendRequests?.includes(userData?.uid));
      const lastProfile = snapshot.docs[snapshot.docs.length - 1];
      setLastProfile(lastProfile);
      profiles.push(...moreProfiles);
      setProfileCount(moreProfiles.length);
      setLoadMoreLoading(false);
    });
  };

  return (
    <div id="feature" className="block featureBlock bgGray">
      <div className="container">
        <div className="titleHolder">
          <h2>Profiles</h2>
        </div>
        {loading ? (
          <Spin />
        ) : profiles &&
          profiles.filter((item) => item.userId !== user.uid).length > 0 ? (
            <>
          <Row gutter={[16, 16]}>
            {profiles
              .filter((item) => item.userId !== user.uid)
              .map((profile) => (
                <Col
                  key={profile.id}
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 8 }}
                >
                  <Link to={`/profile-page/${profile.id}`}>
                    <Badge.Ribbon
                      placement="end"
                      text={`${getAge(profile.dateOfBirth)} yrs`}
                      color="blue"
                    >
                      <Card
                        hoverable
                        cover={
                          <div style={{ overflow: "hidden", height: "240px" }}>
                            <img
                              alt={profile.firstName}
                              style={{ height: "100%", width: "100%" }}
                              src={profile.profileImages[0]?.url}
                            />
                          </div>
                        }
                      >
                        <Meta
                          title={`${profile.firstName} ${profile.lastName}`}
                        />
                        <Typography>
                          {profile.gender} | {profile.maritalStatus} |{" "}
                          {profile.subCaste} | {profile.education} |{" "}
                          {profile.currentResidence}
                        </Typography>
                      </Card>
                    </Badge.Ribbon>
                  </Link>
                </Col>
              ))}
          </Row>
          <div style={{ textAlign: "center", marginTop: "10px" }}>
              { loadMoreLoading ? <Spin /> : <Button disabled={profileCount < 6} onClick={loadMoreProfiles}>
                Load more..
              </Button> }
            </div>
          </>
        ) : (
          <Empty
            image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
            imageStyle={{
              height: 120,
            }}
            description={<span>No profiles found</span>}
          ></Empty>
        )}
      </div>
    </div>
  );
}
