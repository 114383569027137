import { Collapse } from 'antd';
const { Panel } = Collapse;

function AppFAQ() {
  return (
    <div id="faq" className="block faqBlock">
    <div className="container">
      <div className="titleHolder">
        <h2>Frequently Asked Questions</h2>
      </div>
      <Collapse>
        <Panel header="What makes this matrimony website suitable for Sikhs?" key="1">
          <p>Our platform is specifically designed to cater to the needs and preferences of the Sikh community. It includes features such as subcaste filters, cultural preferences to ensure a culturally sensitive and relevant experience.</p>
        </Panel>
        <Panel header="How do I create a profile on the Sikhmatches website?" key="2">
          <p>Creating a profile is simple. Signin using your Google Account, fill in the required details, and provide information about your background, preferences, and what you are looking for in a life partner</p>
        </Panel>
        <Panel header="Can I specify my subcaste preferences in the search?" key="3">
          <p>Absolutely. Our advanced search filters allow you to specify your subcaste preferences, ensuring that you receive match suggestions that align with your cultural and community background.</p>
        </Panel>
        <Panel header="Is horoscope matching available on the website?" key="4">
          <p>Yes, we provide integrated horoscope matching tools to help you assess astrological compatibility with potential matches. This feature is optional, and users can choose to use it based on their preferences.</p>
        </Panel>
        <Panel header="Can I find matches based on educational and professional criteria?" key="5">
          <p>Yes, you can. Our platform allows you to specify educational and professional criteria to find matches that align with your aspirations and preferences.</p>
        </Panel>
        <Panel header="How can I connect with potential matches on the Sikhmatches website?" key="6">
          <p>You can express interest, send messages, and engage with potential matches through our communication tools. We encourage users to communicate and get to know each other before making important decisions</p>
        </Panel>
        <Panel header="Are there community events or gatherings organized through the platform?" key="7">
          <p>We provide information about community events and gatherings in the "Events" section. This feature aims to foster a sense of community and allows users to connect beyond the virtual platform.</p>
        </Panel>
        <Panel header="Can I use the Sikhmatches website on my smartphone?" key="8">
          <p>Yes, we offer a mobile application for added convenience. You can download the app and access the platform from your smartphone, ensuring flexibility in your matrimonial search</p>
        </Panel>
        <Panel header="Are there resources on Sikh culture and traditions available on the website?" key="9">
          <p>Yes, we have a dedicated section for community blogs and resources. Explore informative content on Sikh culture, traditions, and marriage to enhance your understanding and knowledge.</p>
        </Panel>
        <Panel header="Is customer support available if I have questions or concerns?" key="10">
          <p>Yes, we have a responsive customer support team ready to assist you. Feel free to reach out via the "Contact Us" section, and we'll be happy to address any questions or concerns you may have.</p>
        </Panel>
        <Panel header="How is privacy maintained on the Sikh Matrimony website?" key="11">
          <p>We take privacy seriously. Our platform employs robust security measures to ensure the confidentiality of user data. You have control over your privacy settings and can choose what information you want to share.</p>
        </Panel>
      </Collapse>    
    </div>
  </div>  
  );
}

export default AppFAQ;