import React, { useState } from "react";
import { updateDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Select, Row, Col, Typography, message, Spin } from 'antd';
import jsonData from "../data/data.json";
import { useAuthState } from "react-firebase-hooks/auth";
import { signOut } from "firebase/auth";
import { auth, db, } from "../firebaseConfig";

const { TextArea } = Input;
const { Text } = Typography;

function AppDelete() {

    const [form] = Form.useForm();
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(false);
    let navigate = useNavigate();

    const [formData, setFormData] = React.useState({
        reason: "",
        phoneNumber: "",
        message: ""
    })

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelect = (name) => (value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleFinish = async (values) => {
        setLoading(true);
        const userRef = doc(db, "users", user?.uid);

        updateDoc(userRef, {
            disabled: true
        })
            .then(() => {
                setLoading(false);
                signOut(auth);
                message.success(`Your delete request has been received.`, 2);
                navigate("/");
            })
            .catch((err) => {
                console.log(err);
                message.error(`Delete request not be submitted.`, 2);
            });

        setTimeout(() => {
            form.resetFields();
            setLoading(false);
        }, 1000);

    }
    
    return (
        <Spin spinning={loading}>
            <div className="block contactPage">
                <div className="container">
                    <Row gutter={[16, 16]} type="flex"
                        justify="center"
                        align="middle">
                        <Col xs={24} lg={12} xl={12}>
                            <h2>Delete Account</h2>
                            <Form
                                name="deleteForm"
                                form={form}
                                initialValues={{ remember: true }}
                                autoComplete="off"
                                layout="vertical"
                                size='small'
                                onFinish={handleFinish}
                            >
                                <Form.Item
                                    label="Reason for deleting"
                                    name="reason"
                                    rules={[{ required: true, message: 'Please input your reason for deleting!' }]}
                                >
                                    <Select
                                        name="reason"
                                        onChange={handleSelect("reason")}
                                        placeholder="Delete Reason"
                                        showSearch
                                        options={jsonData.deleteReasons}
                                        allowClear
                                    ></Select>
                                </Form.Item>

                                <Form.Item
                                    label="Phone (Optional)"
                                    name="phoneNumber"
                                    onChange={handleChange}
                                >
                                    <Input name="phoneNumber" />
                                </Form.Item>

                                <Form.Item
                                    label="Message"
                                    name="message"
                                    onChange={handleChange}
                                    rules={[{ required: true, message: 'Please input your message!' }]}
                                >
                                    <TextArea name="message" rows={4} />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                            <Text>* Please submit your request and your account with images will be deleted within 24 hours.
                                Once you delete your account, you will have no access to any features on the website.
                            </Text>
                        </Col>
                    </Row>

                </div>
            </div>
        </Spin>
    );
}

export default AppDelete;